.sp-main{
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
}

.sp-main .description {
    width: 35%;
    height: 100%;
    
    display: grid;
    grid-template-rows: 1fr 2fr 4fr;
    grid-template-columns: auto;
    gap: 0.3125rem;
    text-align: left;
}

.desc-grid-item-1 {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
    text-align: center;

    padding: 0.5rem;
}

.desc-grid-item-2 {
    padding: 0.9rem;

    display: flex;
    flex-direction: column;
}

.desc-grid-item-2 > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 90%;
    align-self: center;

    font-size: calc(var(--fs-small) + 0.2rem);
    font-weight: var(--fw-thin);
}

.desc-grid-item-2 ul {
    padding: 0 0 0 0.9rem;
    list-style-type: circle;
}

.desc-grid-item-2 hr {
    border: 1px solid var(--color-white);
}

.desc-grid-item-2 a:visited, .desc-grid-item-2 a:link, .desc-grid-item-2 a:active {
    font-size: var(--fs-nav-link);
    color: var(--color-white);
    text-decoration: none;
    box-shadow: none !important;
    transition: color 0.2s ease-in, font-size 0.2s ease-out, font-weight 0.2s ease-in;
}

.desc-grid-item-2 a:hover {
    font-weight: var(--fw-bold);
    color: var(--color-gold);
}

.ltitle {
    font-weight: var(--fw-bold);
    color: var(--color-gold);
    list-style-type: none;
}

.desc-grid-item-3 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0.5rem;
}

.desc-grid-item-3 p {
    font-size: var(--fs-small);
}

.sp-main .imageHolder{
    width: 65%;
    height: 100%;
    background-color: white;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
}

.img-grid-item {
    overflow: hidden; 
}

.img-grid-item1 {
    grid-row: 1 / span 1;
    grid-column: 1 / span 2;
}

.img-grid-item2{
    grid-row: 2 / span 1;
    grid-column: 1 / span 1;
}

/* Manual Crop */
.img-grid-item2 img{
    transform: translateY(-0.125rem);
}

.img-grid-item3 {
    grid-row: 2 / span 1;
    grid-column: 2 / span 1;
}

.img-grid-item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;
}

@media screen and (max-width: 82.5rem) {
    .sp-main .description {
        width: 80%;
    }

    .desc-grid-item-2 {
        padding: 0.05rem;
    }
}