.links {
    width: 100%;
    height: 6%;
    z-index: 100;
}

.links ul {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    width: 100%;
    list-style-type: none;
    height: 1.25rem;
    margin: 0;
    padding: 0;
    margin-top: 0.625rem;
}

.links nav li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 20%;
    height: 100%;
}


.links a:visited, .links a:link, .links a:active {
    font-size: var(--fs-nav-link);
    color: var(--color-gold-oscuro);
    text-decoration: none;
    box-shadow: none !important;
    transition: color 0.3s ease-in, font-size 0.3s ease-out, font-weight 0.3s ease-in;
}

.links a:hover {
    font-size: calc(var(--fs-nav-link) + 0.2rem);
    font-weight: var(--fw-bold);
    color: var(--color-gold);
    text-decoration: none;
    box-shadow: none !important;
}


@media screen and (max-width: 82.5rem) {
    .links  {
        margin-top: 0.5rem;
    }

    .links a {
        font-size: calc(var(--fs-nav-link) + 0.2rem)
    }
}


