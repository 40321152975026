.socialsCont {
    display: flex;
    flex-direction: row;
    width: 60%;
    height: 2.3rem;
    border: 1px solid var(--color-white);
    border-radius: var(--border-radius-def);
    margin-top: 0.625rem;
    justify-content: space-around;
    align-items: center;
    transition: border-color 0.5s ease-in-out, box-shadow 0.5s ease, height 0.5s ease;
}

.socialsCont:hover {
    border-color: var(--color-gold);
    box-shadow: var(--box-shadow-def);
    height: 2.5rem;
}

.socialButton {
    width: 1.7rem;
    height: 1.7rem;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    padding: 0.025rem;
}

.socialIcon {
    width: 100%;
    height: 100%;
    transition: color 0.5s ease-in-out, height 0.5s ease-in, width 0.5s ease-out;
}

.socialIcon:hover {
    color: gold;
    width: 1.8rem;
    height: 1.8rem;
}

@media screen and (max-width: 82.5rem) {
    .socialsCont {
        margin-top: 0.9rem;
        width: 90%;
        height: 2.5rem;
    }

    .socialButton {
        width: 2rem;
        height: 2rem;
    }
}