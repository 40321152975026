.aboutMeCont {
    width: 80%;
    border: 1px solid var(--color-gold);
    border-radius: var(--border-radius-def);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.languages {
    width: 30%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0.625rem;
}

.languages ul {
    flex-direction: column;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    list-style-type: none;
}

.languages li {
    font-weight: var(--fw-thin);
    color: var(--color-gold);
}

.languages li:nth-child(odd) {
    font-weight: var(--fw-regular);
    color: var(--color-gold-oscuro);
}

.heading {
    font-size: var(--fs-medium);
    font-weight: var(--fw-bold);
}

@media screen and (max-width: 82.5rem) {
    .aboutMeCont{
        width: 99%;
    }

    .languages {
        width: 50%;
    }

    .heading {
        font-size: var(--fs-regular);
    }

    .languages li {
        font-size: var(--fs-small);
    }
}