.resumeCont {
    margin-top: 1.125rem;
    width: 90%;
    height: 100%;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0.5rem;
    justify-items: center;
    align-items: center;
}

.resumeCont img {
    width: 100%;
}

@media screen and (max-width: 82.5rem) {
    .resumeCont {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }

}