:root {
    --color-synth-color: rgb(82, 50, 42)
}

.clickToPlay {
    position: absolute;

    top: 5%;
    left: 10%;
    transition: opacity 1s ease-in-out 1s, color 0.4s ease-in;

    font-size: var(--fs-medium);
    font-family: 'Genos';
}

.typeToPlay {
    position: absolute;

    top: 5%;
    left: 73%;
    transition: opacity 1s ease-in-out 1s, color 0.4s ease-in;

    font-size: var(--fs-medium);
    font-family: 'Genos';
}

.synthCont{
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: background-color 0.3s ease-in;
    background-color: var(--color-synth-color);
    font-family: 'Tektur';
}

.synthCont:focus{
    outline: none;
}

.synthControlsHolder {
    position: relative;
    z-index: 19;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 4fr 3fr;
}

.synthTitleHolder {
    font-weight: var(--fw-extra-bold);
    font-size: var(--fs-big);
}


.controls {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 1em;
}

.controls .control {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--color-gold-oscuro);
    border-radius: var(--border-radius-def);
}

.c1, .c2, .c3 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 1em;

    padding: 1em;
}

.piano-roll {
    display: flex;
    background-color: var(--color-white);
    border-radius: var(--border-radius-def);
}

.key {
    flex: 1;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.5s ease-out;
    font-family: 'Genos', 'sans-serif';
    font-size: var(--fs-medium);
}

.white-key {
    background-color: var(--color-white);
    color: var(--color-black);
}

.black-key {
    background-color: var(--color-black);
    color: var(--color-white);
    position: relative;
    z-index: 1;
}

.key-pressed {
    background-color: var(--color-gold);
    color: var(--color-black);
}
