.weatherbox {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    border-radius: var(--border-radius-def);
    border: 1px solid var(--color-white);
    transition: border-color 0.5s ease-in-out, box-shadow 0.5s ease;
}

.weatherbox:hover {
    box-shadow: var(--box-shadow-def);
}

.wplace {
    font-size: var(--fs-medium);
    margin-top: 0.625rem;
    margin-bottom: 0.1875rem;
    color: var(--color-white);
    width: 90%;

    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: space-evenly;
}

.weatherSelector {
    font-size: var(--fs-medium);
    color: var(--color-white);
    border: 1px solid var(--color-white);
    width: 1.25rem;
    text-decoration: none;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: var(--border-radius-def);
    background-color: transparent;
    transition: background-color 0.3s ease-in, color 0.5s ease-out;
}

.weatherSelector:hover {
    background-color: var(--color-white);
    font-weight: var(--fw-bold);
    color: black;
}

.numbersHolder {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.iconHolder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 33%;
}

.iconHolder img {
    width: 5rem;
    height: 5rem;
    background-color: var(--color-gray-background);
    border-radius: var(--border-radius-def);
}

.temperature {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    width: 33%;
}

.temperature > p {
    font-size: var(--fs-medium);
    font-weight: var(--fw-bold);
}

.wind {
    display: grid;
    width: 33%;
    margin-top: 0.3rem;
}