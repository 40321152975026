@keyframes fadeIn {
    from{
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.container {
    width: 95%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 0.625rem;
    animation: fadeIn 500ms ease-in both;
}

#inner {
    width: 100%;
    display: flex; 
    flex-direction: row;
}

.panel1 {
    width: 30%;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.panel2{
    width: 70%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.mobile {
    display: none;
    width: 100%;
}

@media screen and (max-width: 82.5rem) {
    .mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .panel1, .panel2 {
        display: none;
    }
}
